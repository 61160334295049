
import Vue, { PropType } from 'vue';
import { Swiper } from 'swiper';
import { TTile } from './types';
import TileItem from '@/components/Tile/TileItem.vue';

export default Vue.extend({
  name: 'Tilecontainer',

  components: {
    TileItem,
  },

  props: {
    tiles: {
      type: Array as PropType<Array<TTile>>,
      required: true,
    },
    enableSwiper: {
      type: Boolean,
      required: true,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      swiper: {} as Swiper,
      isFirstSendEventSwiperScroll: true,
      showNextButton: false,
      showPrevButton: false,
    };
  },

  computed: {
    computedClass (): Record<string, boolean> {
      return {
        'swiper--disabled': !this.enableSwiper,
      };
    },
    prevButtonClasses (): Record<string, boolean> {
      return {
        visible: this.showPrevButton && this.showArrows,
      };
    },

    nextButtonClasses (): Record<string, boolean> {
      return {
        visible: this.showNextButton && this.showArrows,
      };
    },
  },

  async mounted () {
    const { default: Swiper } = await import('swiper');
    const { Navigation } = await import('swiper/modules');
    const TileSwiper = this.$refs.swiperTile as HTMLElement;

    this.swiper = new Swiper(TileSwiper, {
      modules: [Navigation],
      spaceBetween: 12,
      observer: true,
      observeParents: true,
      centeredSlidesBounds: true,
      slidesPerView: 'auto',
      slidesOffsetAfter: 16,
      slidesOffsetBefore: 16,
      navigation: {
        prevEl: this.$refs.swiperPrevTile as HTMLElement,
        nextEl: this.$refs.swiperNextTile as HTMLElement,
        disabledClass: 'swiper-button-hidden',
      },
    });

    this.swiper.on('slideChange', () => {
      if (this.isFirstSendEventSwiperScroll) {
        this.$emit('scroll-swiper');
        this.isFirstSendEventSwiperScroll = false;
      }
    });

    this.enableSwiper ? this.swiper.enable() : this.swiper.disable();
  },

  methods: {
    handleMouseLeave (): void {
      this.showNextButton = this.showPrevButton = false;
    },

    handleMouseMove ({ currentTarget, clientX }: MouseEvent): void {
      if (!currentTarget) {
        return;
      }

      const { left, right } = (
        currentTarget as HTMLElement
      )?.getBoundingClientRect();
      const middle = (right - left) / 2;
      const mouseX = clientX - left;

      this.showPrevButton = mouseX < middle && !this.swiper?.isBeginning;
      this.showNextButton = mouseX > middle && !this.swiper?.isEnd;
    },
  },
});
