import Vue, { VNode } from 'vue';

import TheFooter from '~/components/TheFooter.amp/TheFooter.amp.vue';

import type { Menus } from '~/store/menus';

type Data = {
  menu: Array<FooterMenu>,
};

export default Vue.extend({
  name: 'TheFooterContainer',

  render (h): VNode {
    const { info, subscribe, rules } = this.$store.getters.menus as Menus;
    const menu = [info, subscribe, rules];
    const props = { menu };
    return h(TheFooter, { props });
  },
});
