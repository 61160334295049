export const DEBOUNCE_DURATION: number = 500;

export const MENU_SELECTED = {
  selectedMenus: 'Меню',
  selectedDishes: 'Блюда',
  selectedCuisines: 'Кухня',
  selectedIncludedIngredients: 'Добавить ингредиенты',
  selectedExcludedIngredients: 'Исключить ингредиенты',
  selectedCookingMethods: 'Способ приготовления',
  selectedTime: 'Время',
} as const;
export const FILTER_SELECTED_KEYS = [
  'selectedMenus',
  'selectedDishes',
  'selectedCuisines',
  'selectedIncludedIngredients',
  'selectedExcludedIngredients',
  'selectedCookingMethods',
  'selectedTime',
] as const;
export const FILTER_SELECTED_KEYS_MUTABLE = FILTER_SELECTED_KEYS.concat();
export const RESET_TYPES = [
  'cookingMethods',
  'menus',
  'dishes',
  'foundCuisines',
  'foundExcludedIngredients',
  'foundIncludedIngredients',
  'time',
] as const;

export const MENU_TYPES = {
  DISHES: 'dishes',
  MENUS: 'menus',
  CUISINES: 'cuisines',
  COOKINGMETHODS: 'cookingMethods',
  INCLUDEDINGREDIENTS: 'includedIngredients',
  EXCLUDEDINGREDIENTS: 'excludedIngredients',
  TIME: 'time',
} as const;

export const MINIMAL_VALUE_LENGTH = 3;
export const WAIT_TIME_BETWEEN_MULTISELECT = 200;
