
import Vue, { PropType } from 'vue';
import RecommendationBlock from '~/components/RecommendationBlock/RecommendationBlock.vue';
import { formattingItemSection } from '~/utils/formattingData';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';
import type { Article } from '~/services/health/article';

export default Vue.extend({
  name: 'HealthButtonCollection',

  components: {
    RecommendationBlock,
  },

  props: {
    fieldType: {
      type: String as PropType<'category' | 'post_tag' | 'by_name'>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    category: {
      type: String as PropType<string>,
      default: '',
    },
    subcategory: {
      type: String as PropType<string>,
      default: '',
    },
    tag: {
      type: String,
      default: '',
    },
    nameSearch: {
      type: Array as PropType<Array<string>>,
      default: () => [''],
    },
    id: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      articles: [] as Article[],
    };
  },

  async fetch () {
    switch (this.fieldType) {
      case 'category':
        await this.fetchCategory(this.category);
        break;
      case 'post_tag':
        await this.fetchTag(this.tag);
        break;
      case 'by_name':
        await this.fetchByName(this.nameSearch);
        break;
    }
  },

  methods: {
    async fetchTag (tag: string) {
      const response = await this.$healthApi.fetchByTag(tag, 'posts', { limit: 5 });
      trimWpDomainInPlace(response, ['items.url', 'items.categories.url']);
      // @ts-ignore
      this.articles = response.items;
    },

    getFormattingArticles (articles: ISinglePostServerResponse[]): ISingleArticleServerResponse[] {
      return articles.map((article: ISinglePostServerResponse) => formattingItemSection(article));
    },

    async fetchCategory (category: string) {
      const data = await this.$healthApi.fetchCategory(category, 'posts', { limit: 5 });
      trimWpDomainInPlace(data, ['items.url', 'items.categories.url']);
      this.articles = data.items!;
    },

    async fetchByName (names: Array<string>) {
      const articles = await Promise.all(names.map(async (name) => {
        const response = await this.$healthApi.fetchSinglePost(name);
        trimWpDomainInPlace(response.data, ['item.url', 'item.categories.url']);
        return response.data.item;
      }));

      this.articles = articles;
    },
  },
});
