
import Vue from 'vue';

import { parseData } from '@devhacker/shared/utils/parseData';
import CommentsWidget from '~/components/commentWidget/CommentsWidget.vue';

import { Comment } from '@/types';
import { getFullUrl } from '~/utils';
import { FetchLastCommentsSchema } from '~/schemas/talker';

export default Vue.extend({
  name: 'CommentsWidgetContainer',

  components: {
    CommentsWidget,
  },

  data: () => ({
    commentsLast: [] as Array<Comment>,
  }),

  async fetch () {
    const { data } = await this.$commentsApi.fetchLastComments();
    const lastCommentData = parseData(data, FetchLastCommentsSchema);

    if (lastCommentData.success) {
      this.commentsLast = lastCommentData.data;
    }
  },

  watch: {
    '$route.fullPath': '$fetch',
  },

  methods: {
    sendAnalyticsEvent (element: string, postSlug: string) {
      this.$sendYandexMetrika({
        level1: 'Комментарии_переход к комментариям',
        level4: getFullUrl(postSlug),
        level6: `Виджет "${element} комментарии"`,
      });
    },
  },
});
