
import Vue, { PropType } from 'vue';
import Poll from '@/components/lh-ui/Poll/Poll.vue';
import PollArticleCard from '@/components/lh-ui/PollArticleCard/PollArticleCard.vue';
import { QUIZ_API_URL } from '@/constants/config';
import TransitionLayout from '@/components/transition.vue';
import {
  SET_QUICK_POLL_STATE,
  UPDATE_QUICK_POLL_ANSWER,
  FETCH_QUICK_POLL_STAT,
} from '~/constants';

export default Vue.extend({
  name: 'QuickPollContainer',

  components: {
    Poll,
    PollArticleCard,
    TransitionLayout,
  },

  props: {
    inSidebar: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    location: {
      type: String as PropType<string>,
      required: true,
    },
    widgetId: {
      type: String as PropType<string>,
      required: true,
    },
  },

  computed: {
    imagesHost (): string {
      return QUIZ_API_URL;
    },
    quickPollId (): number {
      return this.$store.state.quickPolls.pollsByLocation.byId[`${this.widgetId}`];
    },
    quickPoll (): QuickPoll {
      return this.quickPollId
        ? this.$store.state.quickPolls.polls.byId[this.quickPollId]
        : null;
    },
    isValidQuickPoll (): boolean {
      const {
        quickPoll,
      } = this;
      return Boolean(quickPoll && quickPoll.question && quickPoll.answers?.length === 2 && quickPoll.article?.url && quickPoll.article?.title);
    },
    quickPollIsInitial (): boolean {
      return this.quickPoll && this.quickPoll.state === 'initial';
    },
    quickPollArticleClass (): any {
      return {
        'quick-poll__article--visible': this.isVisibleArticlePoll,
      };
    },
    isVisibleArticlePoll (): boolean {
      return Boolean(this.quickPoll && this.quickPoll?.state === 'answered');
    },
  },

  methods: {
    onAppearPoll (quickPollId: number): void {
      this.$quizApi.setQuickPollViewed(quickPollId)
        .then(() => {
          const localStorageQuickPolls = JSON.parse(localStorage.getItem('quick-polls') as string);
          const newLocalStorageQuickPolls = localStorageQuickPolls || {};

          Object.keys(newLocalStorageQuickPolls).forEach((quickPollId) => {
            if (newLocalStorageQuickPolls[quickPollId].lastWidgetId === this.widgetId) {
              delete newLocalStorageQuickPolls[quickPollId].lastWidgetId;
            }
          });

          newLocalStorageQuickPolls[quickPollId] = newLocalStorageQuickPolls[quickPollId] || {};
          newLocalStorageQuickPolls[quickPollId].lastWidgetId = this.widgetId;

          if (this.quickPoll.state !== 'answered') {
            this.$store.commit(SET_QUICK_POLL_STATE, { quickPollId, statePoll: 'viewed' });
            newLocalStorageQuickPolls[quickPollId].viewed = true;
          }

          localStorage.setItem('quick-polls', JSON.stringify(newLocalStorageQuickPolls));
        });
    },
    isArticlePoll (): boolean {
      return Boolean(this.quickPoll.article?.url && this.quickPoll.article?.title && this.quickPoll.article?.cover);
    },
    onClickedAnswer (quickPollId: number, answerId: number): void {
      this.$store.dispatch(UPDATE_QUICK_POLL_ANSWER, { quickPollId, answerId })
        .then(() => {
          const localStorageQuickPolls = JSON.parse(localStorage.getItem('quick-polls') as string);
          const newLocalStorageQuickPolls = localStorageQuickPolls || {};

          newLocalStorageQuickPolls[quickPollId] = newLocalStorageQuickPolls[quickPollId] || {};
          newLocalStorageQuickPolls[quickPollId].answer = answerId;

          localStorage.setItem('quick-polls', JSON.stringify(newLocalStorageQuickPolls));

          const answerText = this.quickPoll.answers?.find(({ id }) => id === answerId)?.text;

          this.$sendYandexMetrika({
            level1: 'Квизы_Ответы_Быстрый опрос',
            level5: quickPollId,
            level6: answerText || String(answerId),
          });

          return this.$store.dispatch(FETCH_QUICK_POLL_STAT, quickPollId);
        });
    },

    onLinkClick (url: string) {
      this.$sendYandexMetrika({
        level1: 'Квизы_Выбор материала_Быстрый опрос',
        level4: url,
        level5: this.quickPollId,
      });
    },
  },
});
