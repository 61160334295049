
import Vue, { PropOptions } from 'vue';
import LhResizeObserver from '@/directives/resizeObserver/resizeObserver';

import WidgetsRenderer from '~/components/WidgetsRenderer/WidgetsRenderer.vue';

import { isTheRecipePage, isRecipesPage, isRecipesMainPage } from '~/router';

const MARGIN_BOTTOM_CLASS_NAME = 'add-margin-bottom';

export default Vue.extend({
  name: 'WidgetsRendererContainer',
  components: {
    WidgetsRenderer,
  },
  directives: {
    LhResizeObserver,
  },
  props: {
    widgets: {
      required: true,
      type: Array,
    } as PropOptions<TWidget[]>,
    widgetClassName: {
      default: 'widgets-renderer-item',
      type: String,
    } as PropOptions<string>,
    rootElementStyle: {
      required: false,
      default: () => {},
      type: Object,
    } as PropOptions<Object>,
    inSidebar: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    recipesWidgetDisplayType: {
      type: String,
      default: 'default',
    } as PropOptions<'default' | 'big-tiles'>,
  },
  data: () => ({
    // список виджетов, которые должны быть sticky,
    // кроме последнего, который sticky по умолчанию
    widgetsTypeSticky: ['posts-collection'],
  }),
  computed: {
    showWidgets (): Array<TWidget> {
      if (!this.inSidebar) {
        return this.widgets;
      }

      return this.widgets
        // убираем последний виджет, тк он должен прилипать
        .slice(0, -1)
        // убираем остальные виджеты, которые должны прилипать
        .filter((widget) => {
          const isSticky = this.widgetsTypeSticky.find(widgetType => widgetType === widget.type);
          return !isSticky;
        });
    },
    stickyWidgets (): Array<TWidget> | undefined {
      if (!this.inSidebar) {
        return;
      }

      const widgetsSticky = this.widgets
        .slice(0, -1)
        .filter((widget) => {
          return this.widgetsTypeSticky.some(widgetType => widgetType === widget.type);
        });

      return [
        ...widgetsSticky,
        ...this.widgets.slice(-1),
      ];
    },

    // * аналитика рецепты
    recipesWidgetEventCategory (): string {
      switch (true) {
        case isRecipesMainPage(this.$route.name):
          return 'Главная';
        case isTheRecipePage(this.$route.name):
          return 'Рецепты';
        case isRecipesPage(this.$route.name):
          return 'Таксономия';
        default:
          return 'unknown';
      }
    },
  },

  methods: {
    handleResize ({ target, contentRect: { height } }: ResizeObserverEntry): void {
      if (height > 0) {
        target.classList.add(MARGIN_BOTTOM_CLASS_NAME);
      } else {
        target.classList.remove(MARGIN_BOTTOM_CLASS_NAME);
      }
    },

    getWidgetClassNames (widget: TWidget): Array<string> {
      const { widgetClassName } = this;
      const typeClass = `widgets-renderer-item_${widget.type}`;

      let taxonomyPostsWidgetClassName = '';
      if (widget.type === 'taxonomy-posts') {
        const taxonomyPostsWidgetType = ((widget?.items?.length ?? 0) > 2) ? 'slider' : 'cards';
        taxonomyPostsWidgetClassName = `widgets-renderer-item_${widget.type}-${taxonomyPostsWidgetType}`;
      }

      let marginClass = '';
      // Добавляем маржин по умолчанию для ssr, убирается в handleResize если не нужен
      // iframe невозможно ssr'ить потому для них не добавляем
      if (widget.type !== 'html') {
        marginClass = MARGIN_BOTTOM_CLASS_NAME;
      }

      return [widgetClassName, typeClass, taxonomyPostsWidgetClassName, marginClass];
    },

    sendAnalyticsRecipesWidget (recipeAnalyticsData: { slug: string; category: string; }, showMore?: boolean): void {
      if (!this.inSidebar) {
        if (showMore) {
          this.$emit('recipes-widget-show-more', recipeAnalyticsData.slug);
        } else {
          this.$emit('recipes-widget-click-recipe', recipeAnalyticsData.slug);
        }
      }
    },

    // Получение номера виджета "список рецептов" среди виджетов с таким же типом
    getRecipeListWidgetNumber (id: string): number {
      const widgetIndex = this.widgets
        .filter(item => item.type === 'recipes-list')
        .findIndex(item => item.id === id);
      return widgetIndex + 1;
    },
  },
});
