
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import ArticleCardContainerMixin from '~/mixins/ArticleCardContainerMixin';
import FavoriteMethodsMixin from '~/mixins/FavoriteMethodsMixin';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import ArticleCardMiddle from '@/components/lh-ui/ArticleCard/ArticleCardMiddle/ArticleCardMiddle.vue';
import ArticleCardLarge from '@/components/lh-ui/ArticleCard/ArticleCardLarge/ArticleCardLarge.vue';
import { getFullUrl } from '~/utils';

export default ArticleCardContainerMixin.extend({
  name: 'TheTvContainer',

  data () {
    return {
      articlesWithLoadedPixels: [] as number[],
    };
  },

  mixins: [FavoriteMethodsMixin, UpdatingViewsCountMixin],

  components: {
    ArticleCardMiddle,
    ArticleCardLarge,
    IntersectionObserver,
  },

  computed: {
    articles (): Array<IArticle> {
      return this.$store.getters.currentArticles.slice(0, 5);
    },
    firstArticle (): IArticle {
      return this.articles[0];
    },
    otherArticles (): Array<IArticle> {
      return this.articles.slice(1, 5);
    },
  },
  methods: {
    sendEvent (slug: string) {
      this.$sendYandexMetrika({
        level1: 'Комментарии_переход к комментариям',
        level4: getFullUrl(slug),
        level6: 'главная',
      });
    },
    onAppear (article: IArticle): void {
      const testPixel = article?.testPixel;
      const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(
        article.id,
      );
      if (testPixel?.length && isNotLoadedPixel) {
        testPixel.forEach(url => this.$axios(url, { withCredentials: true }));
        this.articlesWithLoadedPixels.push(article.id);
      }
    },
    isTrackTheAppearance (article: IArticle): boolean {
      const hasTestPixel = Boolean(article?.testPixel);
      return hasTestPixel;
    },
    onClickArticleCardLarge (): void {
      const { teaserUrl } = this.firstArticle;
      const url = teaserUrl || this.firstArticle.url;

      this.$sendYandexMetrika({
        level1: 'Выбор материала_Виджет большая карточка',
        level4: getFullUrl(url),
      });
    },
    onClickAuthor (): void {},
    onClickCategory (link: string, titleCategory: string, _title: string): void {
      this.$sendYandexMetrika({
        level1: 'Выбор рубрики',
        level4: getFullUrl(link),
        level5: titleCategory,
        level6: 'unknown',
      });
    },
  },
});
