import { DirectiveOptions } from 'vue';

const directive: DirectiveOptions = {
  bind (el: any, binding: any) {
    if (typeof binding.value === 'function') {
      el.__OrientationChange__ = {
        async callback (event: Event) {
          // добавлена задержка, т.к. не всегда успевает вычисляться
          // правильная ширина
          await new Promise((resolve) => {
            setTimeout(() => resolve(), 300);
          });

          const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';

          binding.value({ event, el, orientation });
        },
      };

      window.addEventListener(
        'orientationchange',
        el.__OrientationChange__.callback,
      );
    }
  },
  unbind (el: any) {
    if (el.__OrientationChange__ && el.__OrientationChange__.callback) {
      window.removeEventListener(
        'orientationchange',
        el.__OrientationChange__.callback,
      );
      delete el.__OrientationChange__;
    }
  },
};

export default directive;
