import Vue, { VNode } from 'vue';

import NavMenuBelowHeader from '~/components/NavMenuBelowHeader.vue';

import type { Menus } from '~/store/menus';

export default Vue.extend({
  name: 'NavMenuBelowHeaderContainer',

  render (h): VNode {
    const props: {
      list: Array<MenuItem>,
    } = {
      list: (this.$store.getters.menus as Menus)?.second?.items ?? [],
    };
    return h(NavMenuBelowHeader, { props });
  },
});
