import { render, staticRenderFns } from "./TheSidebarContainer.amp.vue?vue&type=template&id=274505b8&scoped=true"
import script from "./TheSidebarContainer.amp.vue?vue&type=script&lang=ts"
export * from "./TheSidebarContainer.amp.vue?vue&type=script&lang=ts"
import style0 from "./TheSidebarContainer.amp.vue?vue&type=style&index=0&id=274505b8&prod&lang=scss&scoped=true"
import style1 from "./TheSidebarContainer.amp.vue?vue&type=style&index=1&id=274505b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274505b8",
  null
  
)

export default component.exports