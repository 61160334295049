/* eslint-disable no-param-reassign */
import ResizeObserver from 'resize-observer-polyfill';
import { isFunction } from '~/utils/isfunction';

// the observer instance is shared for performance reasons
// more information: https://github.com/WICG/ResizeObserver/issues/59
const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
  window.requestAnimationFrame(() => {
    entries.forEach((event) => {
    // @ts-ignore
      event.target.resizeHandler(event);
    });
  });
});

const ResizeObserverDirective = {
  bind (el: HTMLElement, { value: resizeHandler }: any) {
    if (!isFunction(resizeHandler)) {
      throw new TypeError('directive value must be a function');
    }
    // @ts-ignore
    el.resizeHandler = resizeHandler;
    observer.observe(el);
  },
  unbind (el: HTMLElement) {
    // @ts-ignore
    if (el.resizeHandler) {
      // @ts-ignore
      delete el.resizeHandler;
      observer.unobserve(el);
    }
  },
};

export default ResizeObserverDirective;
