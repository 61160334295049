import Vue, { VNode } from 'vue';

import PartnerBanner, { PartnerBannerProps } from '~/components/PartnerBanner.vue';

export default Vue.extend({
  name: 'PartnerBannerContainer',

  components: {
    PartnerBanner,
  },

  render (h): VNode {
    const {
      bannerUrl: link,
      bannerImage: image,
    } = this.$store.getters.currentArticle?.meta?.advert ??
    { link: null, image: null };
    const props: PartnerBannerProps = { link, image };

    return h('PartnerBanner', { props });
  },
});
