
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';

import NavMenu from '~/components/NavMenu/NavMenu.vue';

import { HIDE_NAV, IS_NEW_YEAR_LOGO } from '~/constants';
import { isHealthPath, isHomePage } from '~/router';
import Transition from '~/components/transition.vue';

export default Vue.extend({
  name: 'NavMenuContainer',

  components: {
    NavMenu,
    Transition,
  },

  props: {
    isNavMenuVisibleDesktop: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      isNavVisible: (state: any) => (state as IRootState).visibleModalWindows.includes('nav'),
    }),

    healthLogoName (): string {
      return IS_NEW_YEAR_LOGO ? 'new-year-logo-black' : 'health-logo-white';
    },

    logoName (): string {
      return IS_NEW_YEAR_LOGO ? 'new-year-logo-black' : 'lh-logo-black';
    },

    burgerMenuItems () {
      return this.$store.getters.burgerMenuItems;
    },

    socialNetwork () {
      return this.$store.getters.menus.burgerSubs;
    },

    cursorVariable () {
      const { name } = this.$route;
      return {
        '--cursor': isHomePage(name) ? 'auto' : 'pointer',
      };
    },

    getName () {
      return this.isNavMenuVisibleDesktop ? 'fade' : 'nav-menu';
    },

    isHomePage () {
      return isHomePage(this.$route.name);
    },

    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },
  },

  methods: {
    hideNav () {
      this.$store.commit(HIDE_NAV);
    },
  },
});
