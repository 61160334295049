export function resetCSSAndDOM ():void {
  removeBranding();
  removeTopEmptyDiv();
  removeAdFoxGoLink();
  clearBodyStyle();
}

function clearBodyStyle (): void {
  document.body.style.background = '';
  document.body.style.paddingBottom = '0px';
}

// удалим брендирование
function removeBranding (): void {
  const brandingBackgroundElements = document.querySelectorAll('.adfox-banner-background');
  Array.from(brandingBackgroundElements).forEach((el: Element) => {
    el.parentNode && el.parentNode.removeChild(el);
  });
}

// удалим div высотой N px
// этот div ставится для брендирования адфоксом, не нашим кодом
// костыль, но вариантов нет
function removeTopEmptyDiv (): void {
  const contentWrapperForBranding = document.getElementById('content-wrapper-for-branding');
  if (contentWrapperForBranding) {
    const nextElement = contentWrapperForBranding.nextElementSibling;
    if (nextElement) {
      const styleAttribute = nextElement.getAttribute('style');
      if (styleAttribute && styleAttribute.includes('height')) {
        nextElement && nextElement.parentNode && nextElement.parentNode.removeChild(nextElement);
      }
    }
  }
}

// удалить рекламный баннер adFox на мобильных внизу экрана
function removeAdFoxGoLink (): void {
  // чтобы не удалить лишнего выбираем div, которые находятся в body,
  // и в которых есть ссылка на adfox
  // 200984 - ownerId в adFox
  const adFoxGoLinks = document.querySelectorAll('body > div > a[href^="https://ads.adfox.ru/200984/"]');
  Array.from(adFoxGoLinks).forEach((link: Element) => {
    const parentLink = link.parentNode;
    if (parentLink) {
      parentLink.parentNode && parentLink.parentNode.removeChild(parentLink);
    }
  });
}
