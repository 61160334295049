
import Vue from 'vue';

import { HIDE_NAV } from '~/constants';

export default Vue.extend({
  name: 'DarkBackgroundContainer',

  computed: {
    isDarkBackground (): boolean {
      return (this.$store.state as IRootState).visibleModalWindows.includes('nav');
    },
  },

  methods: {
    hideNav () {
      this.$store.commit(HIDE_NAV);
    },
  },
});
