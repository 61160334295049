
import Vue, { PropType } from 'vue';
import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
import { getRelativeLink, getEditLinkHref, NOOP } from '~/utils';
import {
  GET_CURRENT_RELATED_LINK,
  GET_CURRENT_RELATED_RECIPES,
  GET_CURRENT_SIMILAR_LINK,
  GET_CURRENT_SIMILAR_RECIPES,
} from '~/constants';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';

export default Vue.extend({
  name: 'RecipesWidgetContainer',

  components: {
    AdminControlsPanel,
    AppPicture,
  },

  mixins: [UpdatingViewsCountMixin],

  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<WidgetRecipe[]>,
      required: true,
    },
    displayType: {
      type: String as PropType<'default' | 'big-tiles'>,
      default: 'default',
    },
    moreLink: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    recipesWidgetEventCategory: {
      type: String,
      required: true,
    },
    list: {
      type: String as PropType<'similar' | 'custom' | 'related'>,
      required: true,
    },
  },

  computed: {
    computedItems (): WidgetRecipe[] {
      switch (this.list) {
        case 'custom':
          return this.items;
        case 'similar':
          return this.$store.getters[GET_CURRENT_SIMILAR_RECIPES];
        case 'related':
          return this.$store.getters[GET_CURRENT_RELATED_RECIPES];
        default:
          return this.items;
      }
    },
    computedMoreLink (): string {
      switch (this.list) {
        case 'custom':
          return this.moreLink ?? '';
        case 'similar':
          return this.$store.getters[GET_CURRENT_SIMILAR_LINK];
        case 'related':
          return this.$store.getters[GET_CURRENT_RELATED_LINK];
        default:
          return this.moreLink ?? '';
      }
    },
    showAdminControlsPanel (): boolean {
      return (this.$store.state as IRootState).isAuthenticatedInWordpress;
    },
  },

  methods: {
    NOOP,
    getRelativeLink,
    getEditLinkHref,

    onShowMore (): void {
      if (this.computedMoreLink) {
        const slug = getRelativeLink(this.computedMoreLink);
        this.$emit('show-more', {
          slug,
          category: this.recipesWidgetEventCategory,
        });
      }
    },
    onClickRecipe (link: string): void {
      const slug = getRelativeLink(link);
      this.$emit('click-recipe', {
        slug,
        category: this.recipesWidgetEventCategory,
      });
    },
  },
});
